// src/pages/Resume.js
import React, { useContext, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css'; // Import the TextLayer CSS
import { ThemeContext } from '../ThemeContext';

// Load PDF worker from an external CDN
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function Resume() {
  const { mode } = useContext(ThemeContext);
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Container style={{ backgroundColor: mode === 'dark' ? '#121212' : '#ffffff' }}>
      <Typography variant="h4" gutterBottom style={{ color: mode === 'dark' ? '#ffffff' : '#000000' }}>
        Resume
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: 'calc(100vh - 150px)', overflow: 'auto', paddingTop: '20px' }}
      >
        <Document file="/My-Resume.pdf" onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.2} />
          ))}
        </Document>
      </Box>
    </Container>
  );
}

export default Resume;
