import React from 'react';
import { Typography, Box, Grid, Card, CardContent } from '@mui/material';
import './Projects.css'
const projects = [
  {
    title: 'Data Pipeline for Finance',
    company: ['Dora\'s Naturals'],
    description: 'Automation of PDF files being accesible through in-house finance web app',
    technologies: ['Node.js', 'Powershell', 'Oracle12c (lmao)'],
  },
  {
    title: 'Disaster Recovery',
    company: ['Dora\'s Naturals'],
    description: 'General vmware usage w/ scope of disaster recovery / backups',
    technologies: ['vCenter 8', 'Veeam', 'Proxmox (For testing!)'],
  },
  {
    title: 'Networking w/ Terraform',
    company: ['Neuralink'],
    description: 'Using Terraform to manage WAN (Multi-site)',
    technologies: ['Terraform', 'Multiple TF Providers', 'AWS'],
  },
  {
    title: 'Ongoing CI/CD Efforts',
    company: ['Neuralink'],
    description: 'Maintaining Runners and creating visibility into runner system health',
    technologies: ['Python', 'AWS', 'Git', 'Grafana', 'Bazel'],
  },
  {
    title: 'MDM Migration',
    company: ['Neuralink'],
    description: 'Assiting, planning, and supporting company device migration to new MDM Platforms depending on OS',
    technologies: ['MDM Platforms', 'Configuration profiles', 'Windows/MacOS/Linux'],
  },
  {
    title: 'Endless Frugality',
    company: ['Every Company'],
    description: 'Re-use of equipment, OSS Alternatives, ASKING FOR JUSTIFICATION',
    technologies: ['Hardware', 'PDF(lol)', 'Google Apps/Office Trade off', 'In-house data destruction', 'Always being pressed by accounting'],
  },
];

const Projects = () => {
  return (
    <Box textAlign="center" marginBottom="40px">
      <Typography variant="h4" component="h3" gutterBottom>
        Work I've Contributed To
      </Typography>
      <Grid container spacing={4}>
        {projects.map((project, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card className={index === 5 ? "rainbow-flashing" : ""}>
              <CardContent>
                <Typography variant="h5" component="h2">
                  {project.title}
                </Typography>
                <Typography variant="body2" component="p" gutterBottom>
                  <strong>Company:</strong> {project.company.join(', ')}
                </Typography>
                <Typography variant="body2" component="p" gutterBottom>
                  <strong>Technologies:</strong> {project.technologies.join(', ')}
                </Typography>
                <Typography variant="body2" component="p" gutterBottom>
                  <strong>Description: </strong>{project.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};


export default Projects;
