import React, { useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import StorageIcon from '@mui/icons-material/Storage';
import LanguageIcon from '@mui/icons-material/Language';
import WorkIcon from '@mui/icons-material/Work';
import SecurityIcon from '@mui/icons-material/Security';
import ComputerIcon from '@mui/icons-material/Computer';
import CableIcon from '@mui/icons-material/Cable';
import WifiIcon from '@mui/icons-material/Wifi';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';

const osiSkills = [
  { layer: 'Physical Layer', icon: <SettingsEthernetIcon />, skills: [
    { icon: <CableIcon />, text: 'Installing Ethernet cables to desktop computers, access points, and server machines' },
    { icon: <DeviceHubIcon />, text: 'Familiar with patch panels and Ethernet tracer tools' },
    { icon: <WifiIcon />, text: 'Installing NIC cards and drivers, including fiber NICs' },
    { icon: <CableIcon />, text: 'Basic understanding of data and clock signal transmission (e.g., Manchester encoding)' }
  ]},
  { layer: 'Data Link Layer', icon: <StorageIcon />, skills: [
    { icon: <LanguageIcon />, text: 'Basic understanding of MAC addresses and ARP protocol' },
    { icon: <DeviceHubIcon />, text: 'Basic understanding of network switch operations and ARP' },
    { icon: <SettingsEthernetIcon />, text: 'No specific knowledge of Ethernet standards (e.g., 802.3)' }
  ]},
  { layer: 'Network Layer', icon: <LanguageIcon />, skills: [
    { icon: <LanguageIcon />, text: 'Knowledge of IP addressing (IPv4 and IPv6), DHCP, and CIDR notation for subnetting' },
    { icon: <DeviceHubIcon />, text: 'Familiar with routing protocol acronyms (OSPF, BGP, RIP) but no hands-on experience' },
    { icon: <SettingsEthernetIcon />, text: 'Can perform IP subnetting and explain its purpose' }
  ]},
  { layer: 'Transport Layer', icon: <SettingsEthernetIcon />, skills: [
    { icon: <WifiIcon />, text: 'Understands differences and use cases of TCP (requires acknowledgment) and UDP (one-way stream)' },
    { icon: <DeviceHubIcon />, text: 'Examples: sFTP uses TCP, media streaming uses UDP' },
    { icon: <SettingsEthernetIcon />, text: 'No knowledge of flow control mechanisms' },
    { icon: <LanguageIcon />, text: 'No knowledge of error checking mechanisms' }
  ]},
  { layer: 'Session Layer', icon: <WorkIcon />, skills: [
    { icon: <WorkIcon />, text: 'No knowledge of session management' },
    { icon: <SecurityIcon />, text: 'Familiar with EAP-TLS for session authentication' },
    { icon: <ComputerIcon />, text: 'Has used APIs (e.g., TD Ameritrade, Microsoft Graph) but not managed sessions' }
  ]},
  { layer: 'Presentation Layer', icon: <SecurityIcon />, skills: [
    { icon: <SecurityIcon />, text: 'Experienced with software encryption methods, familiar with RSA for SSH, TLS, and secure file transfer' },
    { icon: <DeviceHubIcon />, text: 'Knows about TPM for hardware encryption' },
    { icon: <SettingsEthernetIcon />, text: 'Can use 7-zip and Linux compression methods but not familiar with technical details' },
    { icon: <LanguageIcon />, text: 'No knowledge of different character encoding standards' }
  ]},
  { layer: 'Application Layer', icon: <ComputerIcon />, skills: [
    { icon: <ComputerIcon />, text: 'Basic understanding of HTTP/HTTPS protocols, websockets, and specific ports (80 for HTTP, 443 for HTTPS)' },
    { icon: <SettingsEthernetIcon />, text: 'Familiar with FTP, TFTP, and sFTP protocols and their use cases' },
    { icon: <WifiIcon />, text: 'Familiar with SMTP, POP3, and IMAP but no hands-on experience' },
    { icon: <LanguageIcon />, text: 'Experienced with DNS (creating A records in Route53 and on-prem Windows Server) and DHCP (assigning static IP addresses, creating subnets)' }
  ]}
].reverse(); // Reverse the array to change the order

const OSISkills = () => {
  const [expanded, setExpanded] = useState([]);

  const handleExpandAll = () => {
    setExpanded(osiSkills.map((_, index) => index));
  };

  const handleCollapseAll = () => {
    setExpanded([]);
  };

  const handleToggle = (index) => () => {
    setExpanded((prevExpanded) =>
      prevExpanded.includes(index)
        ? prevExpanded.filter((i) => i !== index)
        : [...prevExpanded, index]
    );
  };

  return (
    <Box textAlign="center" marginBottom="40px">
      <Typography variant="h4" component="h3" gutterBottom>
        OSI Model Skills
      </Typography>
      <Box textAlign="center" marginBottom="20px">
        <Button variant="contained" color="primary" onClick={handleExpandAll} style={{ marginRight: '10px' }}>
          EXPAND ALL
        </Button>
        <Button variant="contained" color="secondary" onClick={handleCollapseAll}>
          COLLAPSE ALL
        </Button>
      </Box>
      {osiSkills.map((skill, index) => (
        <Accordion key={index} expanded={expanded.includes(index)} onChange={handleToggle(index)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <ListItemIcon>{skill.icon}</ListItemIcon>
            <Typography>{skill.layer}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {skill.skills.map((detail, idx) => (
                <ListItem key={idx}>
                  <ListItemIcon>{detail.icon}</ListItemIcon>
                  <ListItemText primary={detail.text} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default OSISkills;
