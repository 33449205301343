// src/components/Footer.js
import React from 'react';
import { Typography, Box } from '@mui/material';

function Footer() {
  return (
    <Box component="footer" sx={{ p: 2, bgcolor: 'text.secondary', color: 'white', textAlign: 'center', mt: 'auto' }}>
      <Typography variant="body1">
        &copy; 2024 Jesse's website
      </Typography>
    </Box>
  );
}

export default Footer;
