import React from 'react';
import { Container, Typography, Box, Card, CardContent, Avatar, Grid, ListItemIcon } from '@mui/material';
import Projects from '../components/Projects';
import OSISkills from '../components/OSISkills';
import CodeIcon from '@mui/icons-material/Code';

const skillsToLearn = [
  { skill: 'Kubernetes', icon: <CodeIcon /> },
  { skill: 'Bazel', icon: <CodeIcon /> },
  { skill: 'Literally everything', icon: <CodeIcon /> },
  // Add more skills as needed
];

const Home = () => {
  return (
    <Container style={{ paddingTop: '50px' }}>
      <Box textAlign="center" marginBottom="40px">
        <Typography variant="h2" component="h1" gutterBottom>
          Welcome to a Website
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Hi, I'm Jesse MacTaggart, a person who does work with computers most of the time, although it's not always good.
        </Typography>
      </Box>

      <Box textAlign="center" marginBottom="40px">
        <Card style={{ maxWidth: 600, margin: 'auto' }}>
          <CardContent>
            <Box display="flex" justifyContent="center" marginBottom="20px">
              <Avatar alt="Jesse MacTaggart" src="/path/to/your/photo.jpg" style={{ width: 100, height: 100 }} />
            </Box>
            <Typography variant="h5" component="h2" gutterBottom>
              About Me
            </Typography>
            <Typography variant="body1" component="p">
              I am a dedicated IT professional with a passion for technology and continuous learning. I have extensive experience working with various layers of the OSI model, from application-level protocols to physical installations. All i want is large sums of money.
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Projects />

      <Box textAlign="center" marginBottom="40px">
        <Typography variant="h4" component="h3" gutterBottom>
          Skills I Have Been Told to Learn, and will continue to refuse to learn
        </Typography>
        <Grid container spacing={4} justify="center">
          {skillsToLearn.map((skill, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <Box marginRight={2}>
                      <ListItemIcon>{skill.icon}</ListItemIcon>
                    </Box>
                    <Typography variant="h6">{skill.skill}</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <OSISkills />
    </Container>
  );
};

export default Home;
